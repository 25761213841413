@import "bootstrap/dist/css/bootstrap.min.css";
@import "./../src/sass/variables";

body {
  font-family: $montserrat-font-family;
}

.custom-overflow {
  overflow-y: hidden;
}

.custom-modal {
  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    border-radius: 1rem;
    border: none;
    box-shadow: $box-shadow-modal;
  }
}

.signup {
  .modal-header {
    font-family: $montserrat-font-family;
    font-weight: 600;
    font-size: 1.5rem;
  }
}

.form-control,
.btn {
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $primary-color;
  }
}

.customScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #a0a0a0;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.customScroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #555;
}

.rc-time-picker-panel-select {
  width: 100% !important;
}

.rc-time-picker-panel,
.rc-time-picker-panel-narrow {
  width: 170px !important;
}

.notificationBell {
  & .dropdown-toggle::after {
    display: none;
  }

  & .dropdown-menu {
    min-width: 25rem;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  & .btn-primary {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    padding-left: 0;
  }

  & .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  & .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
  }
}

@import "./../../sass/variables";

.notificationTab {
  padding: 0.5rem 1rem;
  margin-bottom: 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($primary-color, 0.2);
  }

  &:hover {
    background-color: rgba($primary-color, 0.2);
  }

  & a {
    color: $quaternary-color;

    &:hover {
      text-decoration: none;
    }
  }
}

@import "./../../sass/variables";

.footer {
  background-color: $primary-color-dark;
  padding: 3rem 0;
  color: rgba(255, 255, 255, 0.582);

  &__link-title {
    color: $secondary-color;
    font-family: $montserrat-font-family;
    font-weight: 700;
  }

  &__links-list {
    list-style: none;
    padding: 0;
    color: #fff;
    font-family: $montserrat-font-family;
    font-weight: 400;

    & li {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.582);
      transition: 0.2s;

      &:hover {
        color: #fff;
      }
    }
  }

  &__social-links {
    color: #fff;
    margin-right: 1rem;

    &--list {
      list-style: none;
      padding: 0;
      display: flex;
      margin-bottom: 0;

      &--item {
        background-color: #fff;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        position: relative;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        & img {
          height: 1rem;
          width: 1rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

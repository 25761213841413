@import "./../../sass/_variables.scss";

.header {
  padding: 0.5rem 0;
  box-shadow: $box-shadow;
  font-size: 0.9rem;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 15;
  background: #fff;

  & .logo-container {
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    color: $primary-color;
  }
}

.button-vendor {
  color: $primary-color;
  font-family: $montserrat-font-family;
  border: 1px solid transparent;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  transition: 0.2s;

  &:hover {
    text-decoration: none;
    color: $primary-color;
    border: 1px solid $primary-color;
    box-shadow: $box-shadow;
    transform: translateY(-2px);
  }
}
